@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "luckiest-guy-regular";
    src: url("assets/fonts/LuckiestGuy-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "lalezar-regular";
    src: url("assets/fonts/Lalezar-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "balibold";
    src: url("assets/fonts/BALIBOLD.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@media only screen and (min-width: 1024px) {
    .cursor {
        background-image: url("assets/images/cursor.webp");
    }
}

app-home-page {
    @apply h-full block;
}

.cursor {
    @apply lg:fixed lg:w-8 lg:h-8 lg:bg-cover lg:pointer-events-none lg:z-[9999];
}

html,
body {
    @apply w-full h-full overscroll-none;
}

body {
    @apply bg-[#222222] cursor-none;
    font-family: "lalezar-regular", "luckiest-guy-regular", "balibold";
    touch-action: manipulation; /* Prevent double-tap zoom on touch devices */
}

//
#city-of-gaza {
    background-position-x: right;
    animation:
        cityOfGazaAnimation 5s ease-in-out forwards alternate,
        cityOfGazaSideAnimation 120s 0s ease-in-out forwards alternate infinite;
}

@keyframes cityOfGazaAnimation {
    0% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes cityOfGazaSideAnimation {
    0% {
        background-position-x: right;
    }
    100% {
        background-position-x: left;
    }
}
text {
    // text-rendering: optimizeLegibility;
    // -webkit-font-smoothing: antialiased; /* Improves font rendering */
    // -moz-osx-font-smoothing: grayscale; /* For better rendering on macOS */
    // font-smooth: always; /* For WebKit-based browsers */
    font-weight: normal !important; /* Avoid using heavy weights if they look too dark */
    // text-shadow: none; /* Avoid any text shadow effects */

    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* Standard */
}
